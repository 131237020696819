/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image, ColumnCover, ColumnDiv, PriceWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1b7odf6 --full pb--30 pt--30" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="pb--60">
              
              <Title className="title-box w--400 title-box--invert ls--002" content={"Holandská 854/1"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--invert mt--02" content={"Brno, Brno – Město"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informace"} style={{"backgroundColor":"var(--color-variable-1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--invert" content={"“Citace v obecné češtině znamená uvedení kratší části jiného textu či výroku, obvykle doslovné.”  "}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box subtitle-box--invert" content={"Školné a zápis 21.1."}>
              </Subtitle>

              <Text className="text-box text-box--invert" style={{"maxWidth":430}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cColor2 fs--24" href={"#cenik"} content={"Dozvědět se více"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informace-2"}>
          
          <ColumnWrap className="column__flex el--3" style={{"gridColumnGap":"8%"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"Nikdy ne více než 8 dětí ve třídě"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Více než 10-ti letá praxe s dětmi"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Multilinguální<br>denní přístup"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-3"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--48 title-box--invert" content={"Tvůrčí aktivity"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--48 title-box--invert" content={"Moderní vybavení"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"cenik"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="pr--40" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--invert" content={"Školné a zápis"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Školné se platí vždy v první den."}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Školné se platí vždy v první den, nebo předem po dohodě."}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Školné se platí vždy v první den."}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert pb--20" content={"Dopolední program"}>
              </Subtitle>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 2 dny v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 3 dny v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert pb--20" content={"Celodenní program"}>
              </Subtitle>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Docházka 1 den v týdnu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"nas-tym"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Mgr. Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Vychovatelka"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--4 pl--02 pr--0" columns={"4"}>
            
            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Školka"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakt"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"+420 797 811 2X0<br>info@vase-stranky.com<br>Heršpacká 18/342<br> Brno 635 00<br>Česká republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Provozní doba"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Pondělí - Pátek:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Víkend:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Navigace"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName3]]'>Úvod</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName6]]'>Náš tým</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName5]]'>Školné a zápis</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName7]]'>Fotogalerie</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}